<template>
  <div class="info-slot-container">
    <feather-icon
      v-if="!isLoadedChart"
      icon="XIcon"
      class="ml-auto float-rigth"
      style="cursor: pointer"
      size="25"
      @click="$emit('update:selectedObject', null)"
    />
    <div class="mx-2">
      <div>
        <div :class="`analytic-video-${selectedObject.id} p-1`"></div>
      </div>
      <!-- <div class="px-2">
        <div class="row">
          <carousel
            :v-bind="medias"
            :per-page="1"
            :adjustableHeight="false"
            paginationPosition="bottom-overlay"
            :paginationEnabled="false"
            :centerMode="true"
            paginationActiveColor="#1C3D5E"
            :navigationEnabled="true"
          >
            <div>
              {{ JSON.stringify(medias) }}
            </div>
            <slide
              v-for="(image, index) in medias"
              :key="`slide-${index}`"
              style="position: relative;"
            >
              <div style="position: absolute; width: 100%; height: 100%; z-index: 3;">
                <div style="
                  color: white;
                  width: 100%;
                  background-color: rgba(0,0,0,0) !important;
                  text-shadow: 1px 2px #000;
                  padding: .5rem;
                  top: 0;
                  background: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 0.5) 95%);">
                  <span>{{ selectedObject.camera_name }}</span>
                  <span style="float: right;">{{ formatDateAssigned(selectedObject.alarm_created_at) }}</span>
                </div>

                <div style="
                  position: absolute;
                  color: white;
                  width: 100%;
                  background-color: rgba(0,0,0,0) !important;
                  text-shadow: 1px 2px #000;
                  padding: .5rem;
                  bottom: 0;
                  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));">
                  <span style="bottom: 0;">{{ selectedObject.priority }}</span>
                </div>
              </div>
              <img style="width: 100%" :src="image.url" v-if="image.url"/>
            </slide>
          </carousel>
        </div>
      </div> -->
      <table class="table table-sm">
        <tr>
          <th>{{ $t('intelexvision_event.alarm_category') }}:</th>
          <td>{{ selectedObject.alarm_category }}</td>
        </tr>
        <tr>
          <th>{{ $t('intelexvision_event.type_alarm') }}:</th>
          <td>{{ selectedObject.type_alarm }}</td>
        </tr>
        <tr>
          <th>{{ $t('intelexvision_event.matched_rules') }}:</th>
          <td>{{ selectedObject.matched_rules }}</td>
        </tr>
        <tr>
          <th>{{ $t('intelexvision_event.intelexvision_server') }}:</th>
          <td>{{ selectedObject.intelexvision_server.description }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount } from '@vue/composition-api';
import { Carousel, Slide } from "vue-carousel";
import { Camera } from "@omnicloud/cameras-sdk";
import moment from "moment-timezone";

export default {
  name: "ARTableData",
  props: {
    selectedObject: Object,
    isLoadedChart: {
      default: false,
      type: Boolean
    }
  },
  components: {
    Carousel,
    Slide,
  },
  setup(props) {
    const cameraSDK = ref(null);
    const medias = ref([]);

    onMounted(() => {
      setTimeout(() => {
        const camType = props.selectedObject.camera.camera_type === "bridge" ? "vms" : props.selectedObject.camera.camera_type;

        cameraSDK.value = new Camera(camType, {
          cameraId: props.selectedObject.camera.camera_id,
          token: localStorage.accessToken,
          htmlElement: `.analytic-video-${props.selectedObject.id}`,
          style: "float",
          apiUrl: `${process.env.VUE_APP_BASE_URL}/v1`,
          debugMode: "info|error",
          mode: "default",
          capabilities: {
            closeBtn: false,
            dateRangeBtn: false,
            date: false,
            recordingBtn: false,
            timelineBtn: false,
          },
        });

        let ts = moment(props.selectedObject.alarm_created_at);

        let delayedMin = props.selectedObject.camera.delayed_record_minutes || "00:20:00";
        let forwardMin = props.selectedObject.camera.forward_record_minutes || "00:00:30";

        let start = ts.clone().subtract(moment.duration(delayedMin));
        let end = ts.clone().add(moment.duration(forwardMin));
        if (end.isAfter(moment())) end = ts;

        start = start.format("YYYY-MM-DD HH:mm:ss");
        end = end.format("YYYY-MM-DD HH:mm:ss");

        cameraSDK.value.buildPlayer("historical", start, end);
        medias.value = JSON.parse(JSON.stringify(props.selectedObject.media));
      }, 300);
    });

    onBeforeUnmount(() => {
      try {
        cameraSDK.value.destroy();
      } catch (error) {
        console.log(error);
      }
    });

    const formatDateAssigned = (value) => {
      const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return formattedDate;
    };

    return {
      formatDateAssigned,
      medias,
    };
  }
};
</script>

<style lang="scss" scoped>
.info-slot-container {
  max-height: 100%;
  overflow: auto;
}
</style>
