import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import axios from "@axios";
import { debounce } from "lodash";

export default function useList() {
  const refListTable = ref(null);
  const toast = useToast();
  const unitFilter = ref(null);
  const unitOptions = ref([]);
  const cameraFilter = ref(null);
  const cameraOptions = ref([]);

  const matchedRulesFilter = ref(null);
  const matchedRulesOptions = ref([]);
  const prioritiesFilter = ref(null);
  const prioritiesOptions = ref([]);
  const typeAlarmsFilter = ref(null);
  const typeAlarmsOptions = ref([]);

  const startDatetimeFilter = ref(null)
  const endDatetimeFilter = ref(null)

  const toggleFilter = ref(true)

  axios('v1/unit?hasAnalyticRecord=true').then((response) => {
    unitOptions.value = response.data.data.map((unit) => ({
      label: unit.description,
      value: unit.id,
    }));
  });

  axios('v1/proxy_camera?hasAnalyticRecord=true&max=5000').then((response) => {
    cameraOptions.value = response.data.data.map((camera) => ({
      label: camera.camera.name,
      value: camera.id,
    }));
  });

  axios('v1/matched_rules/analytic_record').then((response) => {
    matchedRulesOptions.value = response.data.data.map(matchedRule => ({
      label: matchedRule,
      value: matchedRule,
    }));
  });

  axios('v1/priorities/analytic_record').then((response) => {
    prioritiesOptions.value = response.data.data.map(priority => ({
      label: priority,
      value: priority,
    }));
  });

  axios('v1/types_alarms/analytic_record').then((response) => {
    typeAlarmsOptions.value = response.data.data.map(typeAlarm => ({
      label: typeAlarm,
      value: typeAlarm,
    }));
  });

  store.dispatch('tenant/getAll')
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  const tableColumns = [
    { label: "camera.title", key: "camera_name", sortable: true },
    { label: "intelexvision_event.id_alarm", key: "id_alarm", sortable: true },
    { label: "intelexvision_event.image", key: "image", sortable: true },
    { label: "intelexvision_event.type_alarm", key: "type_alarm", sortable: true },
    { label: "intelexvision_event.alarm_created", key: "alarm_created_at", sortable: true },
    { label: "intelexvision_event.intelexvision_server", key: "intelexvision_server.description", sortable: true },
    { label: "intelexvision_event.matched_rules", key: "matched_rules", sortable: true },
    { label: "intelexvision_event.priority", key: "priority", sortable: true },
    { label: "date.created_at", key: "created_at", sortable: true, formatter: "formatDateAssigned" },
    { label: "date.updated_at", key: "updated_at", sortable: true, formatter: "formatDateAssigned" },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("analytic_record/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        unit: unitFilter.value,
        tenant: tenantFilter.value,
        camera: cameraFilter.value,
        matched_rules: matchedRulesFilter.value,
        priority: prioritiesFilter.value,
        type_alarm: typeAlarmsFilter.value,
        from: startDatetimeFilter.value,
        to: endDatetimeFilter.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };
  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([
    unitFilter,
    cameraFilter,
    tenantFilter,
    matchedRulesFilter,
    prioritiesFilter,
    typeAlarmsFilter,
    startDatetimeFilter,
    endDatetimeFilter,
    currentPage,
    perPage,
  ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  const applyChartFilters = (filters) => {
    if (filters) {

      tenantFilter.value = filters['id_tenant'] || null;
      cameraFilter.value = filters['id_camera_proxy'] || null;
      unitFilter.value = filters['id_unit'] || null;
      typeAlarmsFilter.value = filters['type_alarm'] || null;
      matchedRulesFilter.value = filters['matched_rules'] || null;

      if (filters['from'] || filters['to']) {
        startDatetimeFilter.value = filters['from'];
        endDatetimeFilter.value = filters['to'];
      }
    }
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    unitFilter,
    unitOptions,
    cameraFilter,
    cameraOptions,
    tenantOptions,
    tenantFilter,
    refetchData,

    matchedRulesFilter,
    matchedRulesOptions,
    prioritiesFilter,
    prioritiesOptions,
    typeAlarmsFilter,
    typeAlarmsOptions,

    startDatetimeFilter,
    endDatetimeFilter,

    toggleFilter,
    applyChartFilters,
  };
}
