<template>
  <div>
    <toastification-loading-content ref="loadingToast"/>
    <toastification-progress-content ref="progressToast"/>

    <!-- Breadcrumb -->
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
      v-if="showFilters"
    />

    <div
      class="filter-container"
      @click="toggleFilter = !toggleFilter"
      v-if="showFilters"
    >
      <!-- <b-icon-filter-circle-fill scale="2" variant="secondary"/> -->

      <b-iconstack v-if="showFilters">
        <b-icon-filter-circle-fill stacked scale="2" variant="secondary"/>
        <b-icon-slash stacked scale="2" variant="white" v-show="toggleFilter" />
      </b-iconstack>
    </div>

    <Transition name="bounce">
      <!-- Filters -->
      <filters
        v-show="toggleFilter"
        :unit-filter.sync="unitFilter"
        :unit-options="unitOptions"
        :tenant-filter.sync="tenantFilter"
        :tenant-options="tenantOptions"
        :camera-filter.sync="cameraFilter"
        :camera-options="cameraOptions"
        :type-alarms-filter.sync="typeAlarmsFilter"
        :type-alarms-options="typeAlarmsOptions"
        :matched-rules-filter.sync="matchedRulesFilter"
        :matched-rules-options="matchedRulesOptions"
        :priorities-filter.sync="prioritiesFilter"
        :priorities-options="prioritiesOptions"
        :start-datetime.sync="startDatetimeFilter"
        :end-datetime.sync="endDatetimeFilter"
        v-if="showFilters"
      />
    </Transition>

    <b-card-group deck>
      <!-- Table Container Card -->
      <b-card :class="isLoadedChart ? '':'m-2'" no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label class="text-capitalize">{{ $t("table.show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="text-capitalize">{{ $t("table.entries") }}</label>
            </b-col>

            <!-- Search and Export-->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('table.search')"
                />
                <b-button
                  @click="exportExcel()"
                  variant="success"
                  class="text-capitalize"
                  type="button"
                  :disabled="buttonExport"
                >
                  <feather-icon icon="DownloadCloudIcon" size="15"/>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Table-->
        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('no_record_found')"
          :sort-desc.sync="isSortDirDesc"
          @row-selected="onRowSelected"
          select-mode="single"
          selectable
        >
          <template #head()="data">
            <span> {{ $tc(data.label, 1) }}</span>
          </template>
          <template #cell(image)="data">
            <div class="hoverable">
              <b-img
                :src="data.item.media && data.item.media.length > 0 ? data.item.media[0].url : ''"
                alt="path Image"
                style="max-height: 120px"
                @click="openImageModal(data.item.media[0].url)" 
                v-b-modal.image_modal 
              ></b-img>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted text-capitalize">{{
                  $t("table.showing", {
                    from: dataMeta.from,
                    to: dataMeta.to,
                    of: dataMeta.of,
                  })
                }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <Pagination
                :totalList="totalList"
                :currentPage="currentPage"
                @updatePagination="update"
                :perPage="perPage"
              >
              </Pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <!-- Modal -->
      <b-card class="m-2 sticky-card pb-2" v-if="selectedObject" no-body>
        <a-r-table-data :selected-object.sync="selectedObject" :key="selectedObject.id"/>
      </b-card>
    </b-card-group>
    <image-modal/>
  </div>
</template>

<script>
import {
  BCard,
  BCardGroup,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BIconstack,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref, computed, watch} from "@vue/composition-api";
import useList from "./useList";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent
  from "@core/components/toastification/ToastificationLoadingContent.vue";
import ToastificationProgressContent
  from "@core/components/toastification/ToastificationProgressContent.vue";
import store from "@/store";
import {useToast} from "vue-toastification/composition";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Filters from "./Filters";
import Pagination from "@/components/Pagination.vue";

import LayoutApi from "@/libs/LayoutApi"
import ARTableData from "../../components/GridView/layouts/slots/ARTableData.vue"
import { SLOT_TYPES } from "@/config/layoutConfig";
import ImageModal from '../../components/GridView/widgets/ImageModal.vue';

export default {
  components: {
    Pagination,
    BCardGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BIconstack,
    Breadcrumb,
    ToastificationLoadingContent,
    ToastificationProgressContent,
    Filters,
    vSelect,
    ARTableData,
    ImageModal,
  },
  props: {
    filters: {
      type: Object | Array,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    isLoadedChart: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination
    // start breadcrumb and export
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("intelexvision_event.title");
    // end breadcrumb
    const selectedObject = ref(null);
    const buttonExport = ref(false);
    const loadingToast = ref(null);
    const progressToast = ref(null);
    const progress = computed(() => store.getters["analytic_record/getProgress"]);
    const error = computed(() => store.getters["analytic_record/getError"]);
    const url = computed(() => store.getters["analytic_record/getUrl"]);

    store.dispatch("grid/findDownloadNotification", "analytic-record").then((data) => {
      if (data.length > 0) {
        buttonExport.value = true;
        progressToast.value.show("Descarga", "Generando archivo...", "secondary");
      }
    });

    const toast = useToast();
    const exportExcel = () => {
      buttonExport.value = true;
      store.dispatch("analytic_record/resetProgress");
      progressToast.value.show("Descarga", "Generando archivo...", "secondary");
      store
        .dispatch("analytic_record/getAll", {
          q: searchQuery.value,
          max: perPage.value,
          "page[n]": currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          unit: unitFilter.value,
          tenant: tenantFilter.value,
          camera: cameraFilter.value,
          matched_rules: matchedRulesFilter.value,
          priority: prioritiesFilter.value,
          type_alarm: typeAlarmsFilter.value,
          from: startDatetimeFilter.value,
          to: endDatetimeFilter.value,
          action: "export",
        })
        .then((response) => {
          store.dispatch(
            "analytic_record/setExportAnalyticRecord",
            response.data.jobId
          );
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error exportando la información",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          buttonExport.value = false;
          progressToast.value.close();
        });
    };

    watch([progress], () => {
      progressToast.value.updateProgress(progress.value);
    });

    watch([error], () => {
      toast({
        component: ToastificationContent,
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      buttonExport.value = false;
      progressToast.value.close();
    });

    watch([url], () => {
      if (url.value) {
        const link = document.createElement("a");
        link.href = url.value;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        buttonExport.value = false;
        progressToast.value.close();
      }
    });

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      unitFilter,
      unitOptions,
      cameraFilter,
      cameraOptions,
      tenantOptions,
      tenantFilter,

      matchedRulesFilter,
      matchedRulesOptions,
      prioritiesFilter,
      prioritiesOptions,
      typeAlarmsFilter,
      typeAlarmsOptions,

      startDatetimeFilter,
      endDatetimeFilter,

      toggleFilter,
      applyChartFilters,
    } = useList();

    const onRowSelected = (item) => {
      if (!props.isLoadedChart) {
        selectedObject.value = item[0];
      } else {
        const layoutApi = new LayoutApi(store);
        const slotType = SLOT_TYPES.AR_TABLE_DATA;

        // setSlotData(slotType, 'ar_table_data', item[0])

        setTimeout(() => {
          layoutApi.setSlotData(slotType, `AR_DATA_${item[0].id}`, item[0]);
        }, 300)
      }

    };
    const formatPercentages = (num) => {
      return `${(parseFloat(num) * 100).toFixed(2)} %`;
    };

    applyChartFilters(props.filters);

    const openImageModal = async (imageUrl) => {
      await store.dispatch("image_modal/imageUrl", imageUrl);
    };

    return {
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      unitOptions,
      unitFilter,
      cameraFilter,
      cameraOptions,
      tenantOptions,
      tenantFilter,
      selectedObject,
      onRowSelected,
      formatPercentages,
      exportExcel,
      buttonExport,
      loadingToast,
      progress,
      progressToast,
      update,

      matchedRulesFilter,
      matchedRulesOptions,
      prioritiesFilter,
      prioritiesOptions,
      typeAlarmsFilter,
      typeAlarmsOptions,

      startDatetimeFilter,
      endDatetimeFilter,

      toggleFilter,
      openImageModal,
    };
  },
};
</script>

<style lang="scss" scoped>

.sticky-card {
  position: sticky;
  top: 6rem;
  height: 80vh;
  overflow: auto;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.filter-container {
  position: absolute;
  right: 2rem;
  top: 5rem;
  cursor: pointer;
}

//
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
</style>
