<template>
  <b-card no-body class="m-1">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{$t('filter')}}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{ $tc('unit.title', 2) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="unitFilter"
            :options="unitOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:unitFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{ $tc('camera.title', 2) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="cameraFilter"
            :options="cameraOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:cameraFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2 text-capitalize">
          <label class="text-capitalize">{{ $tc('tenants.title', 1) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tenantFilter"
            :options="tenantOptions"
            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:tenantFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2 text-capitalize">
          <label class="text-capitalize">{{ $t('intelexvision_event.matched_rules') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="matchedRulesFilter"
            :options="matchedRulesOptions"
            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:matchedRulesFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2 text-capitalize">
          <label class="text-capitalize">{{ $t('intelexvision_event.type_alarm') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="typeAlarmsFilter"
            :options="typeAlarmsOptions"
            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:typeAlarmsFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2 text-capitalize">
          <label class="text-capitalize">{{ $t('intelexvision_event.priority') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="prioritiesFilter"
            :options="prioritiesOptions"
            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:prioritiesFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2">
              <b-form-group>
                <label class="text-capitalize">Start Datetime</label>
                <flat-pickr
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:s', time_24hr: true}"
                  :value="startDatetime"
                  @on-change="(selectedDates, val) => $emit('update:startDatetime', val)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3" md="3" class="mb-md-0 mb-2">
              <b-form-group>
                <label class="text-capitalize">End Datetime</label>
                <flat-pickr
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:s', time_24hr: true}"
                  :value="endDatetime"
                  @on-change="(selectedDates, val) => $emit('update:endDatetime', val)"
                />
              </b-form-group>
            </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue"
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    vSelect,
    flatPickr,
  },
  props: {
    unitFilter: {
      type: Array,
      default: null,
    },
    unitOptions: {
      type: Array,
      default: null,
    },
    cameraFilter: {
      type: Array,
      default: null,
    },
    cameraOptions: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    matchedRulesOptions: {
      type: Array,
      default: null,
    },
    matchedRulesFilter: {
      type: Array,
      default: null,
    },
    typeAlarmsOptions: {
      type: Array,
      default: null,
    },
    typeAlarmsFilter: {
      type: Array,
      default: null,
    },
    prioritiesOptions: {
      type: Array,
      default: null,
    },
    prioritiesFilter: {
      type: Array,
      default: null,
    },
    startDatetime: {
      type: String,
      default: null,
    },
    endDatetime: {
      type: String,
      default: null,
    },
  },
};
</script>